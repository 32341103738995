import './src/assets/styles/global.css'
import './src/assets/fonts/stylesheet.css'
import wrapWithProviders from './wrap-with-providers'

export const wrapRootElement = wrapWithProviders

const trackPageView = (location) => {
  if (window.gatsbyPluginGDPRCookiesGoogleAnalyticsAdded && window.gtag) {
    console.log(location, window.gtag)
    window.gtag('set', 'page_path', location.pathname)
    window.gtag('event', 'page_view')
  }
}

export const onRouteUpdate = ({ location }) => {
  trackPageView(location)
}
